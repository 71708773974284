// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var fileSizeText = Cx.cx([
      "text-gray-700",
      "text-sm",
      "leading-5",
      "mr-3",
      "mr-12"
    ]);

var fileNameText = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "leading-5",
      "ml-2.5"
    ]);

var documentRow = Cx.cx([
      "py-4",
      "border-b",
      "border-gray-100",
      "last:mb-2.5",
      "first:border-t"
    ]);

var marginGrid = "m-0!";

var marginRow = "mb-5!";

var labelContainer = "pl-0";

var resourceDetailField = "h-100";

var buttonCreateContainer = "pl-2";

var uploadDocumentButton = "[&_svg]:mr-2.5";

var flexContainer = "flex";

var iconView = "mr-4";

var iconDelete = "cursor-pointer";

var editor = "mb-0";

var privateDownload = "mr-4";

export {
  marginGrid ,
  marginRow ,
  labelContainer ,
  resourceDetailField ,
  buttonCreateContainer ,
  uploadDocumentButton ,
  flexContainer ,
  iconView ,
  iconDelete ,
  fileSizeText ,
  fileNameText ,
  documentRow ,
  editor ,
  privateDownload ,
}
/* fileSizeText Not a pure module */
